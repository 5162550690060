import React from "react";

const TextPath = ({data, me}) => {
  return (
    <div className={`msg-bubble ${me ? 'msg-bubble-human' : ''}`}>
      <div className="message-container">
        <div className="message-wrapper ">
          <div className="content" dir="ltr">
            <span className="truncate-overflow-test false svelte-guidwd" style={{linesShown: 10}}>
              <div className="inner-html-wrapper">
                <p>
                  {
                    data.display ? data.display.toString().replaceAll("<br>", "\n") :
                    data.text ? data.text.toString().replaceAll("<br>", "\n") : ''
                  }
                </p>
              </div>
            </span>
          </div>
        </div>
        <div className="time" style={{fontSize: '9px'}}>{data.dateTime}</div>
      </div>
    </div>
  )
}

export default TextPath;
