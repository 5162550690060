import PropTypes from "prop-types";
import React, {useEffect, Suspense, lazy} from "react";
import ScrollToTop from "./helpers/scroll-top";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {ToastProvider} from "react-toast-notifications";
import {multilanguage, loadLanguages} from "redux-multilanguage";
import {connect} from "react-redux";
import {BreadcrumbsProvider} from "react-breadcrumbs-dynamic";
import {me} from "./redux/actions/authActions";
import axios from "axios";
import * as env from "./env.json";
import ChatIcon from "./components/chat/ChatIcon";
import ChatGadget from "./components/chat/ChatGadget";

// home pages
const Home = lazy(() => import("./pages/home/HomeMedicalEquipment"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const BookAppointment = lazy(() => import("./pages/other/BookAppointment"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Chat = lazy(() => import("./pages/other/Chat"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  const CustomRoute = ({component: Component, chat, ...props}) => {
    const CustomComponent = (props) => {
      return (
        <div>
          {chat && (
            <ChatGadget {...props}/>
          )}
          <Component
            {...props}/>
        </div>
      )
    }
    return (
      <Route
        {...props}
        component={CustomComponent}
      />
    )
  }

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <CustomRoute
                  exact
                  chat
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />

                {/* Homepages */}
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/home"}
                  component={Home}
                />

                {/* Shop pages */}
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopGridStandard}
                />

                {/* Shop product pages */}
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  component={ProductTabLeft}
                />


                {/* Other pages */}
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <CustomRoute
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/book-appointment"}
                  component={BookAppointment}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <CustomRoute
                  chat
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <CustomRoute
                  path={process.env.PUBLIC_URL + "/mobilechat"}
                  component={Chat}
                />

                <Route exact component={NotFound}/>
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
